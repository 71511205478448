import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { RequestComponent } from './request/request.component';
import { GetstartedComponent } from './getstarted/getstarted.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FlowComponent } from './flow/flow.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CallLogsComponent } from './call-logs/call-logs.component';
import { SignupComponent } from './signup/signup.component';
import { TrainAgentComponent } from './train-agent/train-agent.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { NewPathwayComponent } from './new-pathway/new-pathway.component';
import { AuthGuard } from './auth.guard';
import { IntegrationComponent } from './integration/integration.component';
import { PostComponent } from './post/post.component';
import { BlogsComponent } from './blogs/blogs.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignupUsersComponent } from './signup-users/signup-users.component';
import { LoginUsersComponent } from './login-users/login-users.component';

const routes: Routes = [{
  path: '',
  component: HomeComponent,
  pathMatch: 'full',
},
{
  path: `home`,
  component: HomeComponent
},
{
  path: `es`,
  component: HomeComponent
},
{
  path: `es/home`,
  component: HomeComponent
},
{
  path: `product`,
  component: ProductComponent
},
{
  path: `es/product`,
  component: ProductComponent
},
{
  path: `pricing`,
  component: PricingComponent
},
{
  path: `es/pricing`,
  component: PricingComponent
},
{
  path: `contact`,
  component: ContactComponent
},
{
  path: `es/contact`,
  component: ContactComponent
},
{
  path: `about`,
  component: AboutComponent
},
{
  path: `es/about`,
  component: AboutComponent
},
{
  path: `newsletter`,
  component: NewsletterComponent
},
{
  path: `es/newsletter`,
  component: NewsletterComponent
},
{
  path: `request`,
  component: RequestComponent
},
{
  path: `es/request`,
  component: RequestComponent
},
{
  path: `get-started/:name`,
  component: GetstartedComponent
},
{
  path: `es/get-started/:name`,
  component: GetstartedComponent
},
{
  path: `authorize`,
  component: LoginComponent
},
{
  path: `es/authorize`,
  component: LoginComponent
},
{
  path: `authorize-users`,
  component: LoginUsersComponent
},
{
  path: `es/authorize-users`,
  component: LoginUsersComponent
},
{
  path: `signup`,
  component: SignupComponent,
},
{
  path: `es/signup`,
  component: SignupComponent,
},
{
  path: `signup-users`,
  component: SignupUsersComponent,
},
{
  path: `es/signup-users`,
  component: SignupUsersComponent,
},
{
  path: `integration`,
  component: IntegrationComponent,
},
{
  path: `es/integration`,
  component: IntegrationComponent,
},
{
  path: `forgot_password`,
  component: ForgotPasswordComponent
},
{
  path: `es/forgot_password`,
  component: ForgotPasswordComponent
},
{
  path: `train_agent`,
  component: TrainAgentComponent,
  canActivate: [AuthGuard]
},
{
  path: `es/train_agent`,
  component: TrainAgentComponent,
  canActivate: [AuthGuard]
},
{
  path: `account`,
  component: AccountSettingsComponent,
  canActivate: [AuthGuard]
},
{
  path: `es/account`,
  component: AccountSettingsComponent,
  canActivate: [AuthGuard]
},
{
  path: `new_path/:name`,
  component: NewPathwayComponent,
  canActivate: [AuthGuard]
},
{
  path: `es/new_path/:name`,
  component: NewPathwayComponent,
  canActivate: [AuthGuard]
},
{
  path: `dashboard/:name`,
  component: DashboardComponent,
  canActivate: [AuthGuard]
},
{
  path: `es/dashboard/:name`,
  component: DashboardComponent,
  canActivate: [AuthGuard]
},
{
  path: `post/:name`,
  component: PostComponent
},
{
  path: `es/post/:name`,
  component: PostComponent
},
{
  path: `blogs`,
  component: BlogsComponent
},
{
  path: `es/blogs`,
  component: BlogsComponent
},
{
  path: `privacy-policy`,
  component: PrivacyPolicyComponent
},
{
  path: `es/privacy-policy`,
  component: PrivacyPolicyComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
