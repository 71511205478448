import { Component } from '@angular/core';
import { AppService } from '../app.service';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

interface Blog {
  Description: string;
  country: string;
  id: string;
  image_name: string;
  link: string;
  title: string;
  is_deleted: string;
}

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent {
  public language: any;
  public url: string = '';
  public blogsList: Blog[] = [];
  public filteredBlogs: Blog[] = []; 

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.url = this.router.url;

    if (this.url.startsWith('/es/blogs')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }

    this.getBlogDetails();
  }

  private getBlogDetails(): void {
    this.appService.getBlogs().subscribe(
      (res) => {
        if (res && res.data && res.data.blogs) {
          this.blogsList = res.data.blogs;

          
          this.filteredBlogs = this.blogsList.filter(blog => blog.country === this.language.country_code);

          console.log('Filtered Blogs:', this.filteredBlogs);
          console.log('Language set to:', this.language.country_code);
        }
      },
      (error) => {
        console.error('Error fetching blog details:', error);
      }
    );
  }
}
