import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EMPTY, empty, NEVER, never, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  API_URL = environment.auth.apiUrl;
  // API_URL = "https://q6wq78yljd.execute-api.ap-south-1.amazonaws.com/staging";
  // API_URL = "http://localhost:3000";
  SERVICE_API_URL = environment.auth.serviceApiUrl;
  HASURA_API_URL = environment.auth.hasuraApiUrl;
  HASURA_SECRET_KEY = environment.auth.hasuraSecretKey;

  constructor(private http: HttpClient) { }

  formSubmission(data: any): Observable<any> {
    let requestBody = {
      ...data
    };
    return this.http.post(
      `https://submit-form.com/N9YprmcOp`,
      requestBody
    );
  }

  textToSpeech(data: any): Observable<any> {
    let requestBody = {
      "content": data.text,
      "voiceLang": data.voice_lang,
      "voiceName": data.voice_name
    };
    return this.http.post(
      `${this.SERVICE_API_URL}/text-to-speech`,
      requestBody
    );
  }

  baseToAudio(base64Audio: any) {
    const binaryString = window.atob(base64Audio);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    const audioBlob = new Blob([bytes], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  }
  async convertToAudio(audioBlob: any) {
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  }

  getPlaceIdbyPhone(data: any): Observable<any> {
    let requestBody = {
      phone: data
    };
    return this.http.post(
      `${this.API_URL}/google/getPlaceIdbyPhone`,
      requestBody
    );
  }
  getPlacebyeId(data: any): Observable<any> {
    let requestBody = {
      place_id: data
    };
    return this.http.post(
      `${this.API_URL}/google/getPlacebyId`,
      requestBody
    );
  }

  updateBusinessDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');
    console.log('data', data)

    let requestBody = { "query": "mutation updateBusiness ($id: uuid!, $user_id: uuid, $agent_name: String, $business_address: String, $business_name: String, $business_phone: String, $business_type: String, $main_business_type: String, $dummy_voices: json, $agent_phone_number: String) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {agent_name: $agent_name, business_address: $business_address, business_name: $business_name, business_phone: $business_phone, business_type: $business_type, main_business_type:$main_business_type, user_id: $user_id, dummy_voices: $dummy_voices, agent_phone_number: $agent_phone_number}) {\r\n    agent_name\r\n    business_address\r\n    business_name\r\n    business_phone\r\n    menu\r\n    business_type\r\n  main_business_type\r\n  created_at\r\n    dummy_voices\r\n    id\r\n    idDeleted\r\n    user_id\r\n    updated_at\r\n    website_details\r\n    google_details\r\n    agent_phone_number\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.id, "user_id": data.user_id, "agent_name": data.agent_name, "business_address": data.business_address, "business_name": data.business_name, "business_phone": data.business_phone, "business_type": data.business_type, "main_business_type": data.main_business_type,  "dummy_voices": data.dummy_voices, "agent_phone_number": data.agent_phone_number } }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  callLogs(data: any): Observable<any> {
    let requestBody = {
      "phone_number": data.phone_number,
      "type": data.type
    }
    return this.http.post(
      `${this.API_URL}/twilio/callLogs`, requestBody);
  }

  fetchCallTags(callSidArr: string[]): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = {
      "query": `
      query GetCallTags($call_sid: [String!]) {
        twilio_call_history(where: {call_sid: {_in: $call_sid}}) {
          call_sid
          caller_phone_number
          created_at
          intent_keywords_array
        }
      }
    `, "variables": {
        "call_sid": callSidArr
      }
    }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  callDetailsById(data: any): Observable<any> {
    let requestBody = {
      callSid: data.callSid
    };
    return this.http.post(
      `${this.API_URL}/twilio/callDetailsById`, requestBody);
  }
  callAnalyticsWithFilter(data: any): Observable<any> {
    let requestBody = {
      startDate: data.startDate,
      endDate: data.endDate,
      agent_phone: data.agent_phone
    };
    return this.http.post(
      `${this.API_URL}/twilio/callAnalyticsWithFilter`, requestBody);
  }

  getAgentDetails(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getAgents {\r\n  agents {\r\n    agent_name\r\n    icon\r\n    id\r\n    voice\r\n    voice_lang\r\n    voice_name\r\n    isDeleted\r\n    spanish_voice\r\n    spanish_voice_name\r\n    spanish_voice_lang\r\n  }\r\n}", "variables": {} }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  addAgentNumberToBusiness(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation addAgentNumberToBussiness ($id: uuid!, $agent_phone_number: String) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {agent_phone_number: $agent_phone_number}) {\r\n    website_url\r\n    user_id\r\n    updated_at\r\n    poc_contact_number\r\n    isDeleted\r\n    id\r\n    menu\r\n    dummy_voices\r\n    created_at\r\n    business_type\r\n    business_phone\r\n main_business_type\r\n business_name\r\n    business_hours\r\n    business_address\r\n    agent_phone_number\r\n    agent_id\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.id, "agent_phone_number": data.agent_phone_number } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getBotDetails(id: any, isDeleted: boolean): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getBotData($business_id: uuid, $isDeleted: Boolean) {\r\n  chatbot_training_data(where: {business_id: {_eq: $business_id}, isDeleted: {_eq: $isDeleted}}) {\r\n    business_id\r\n    created_at\r\n    data\r\n    id\r\n    isDeleted\r\n    updated_at\r\n  }\r\n}\r\n", "variables": { "business_id": id, "isDeleted": isDeleted } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updateBotDetails(data: any, id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation updateBotData($business_id: uuid, $data: json) {\r\n  update_chatbot_training_data(where: {business_id: {_eq: $business_id}}, _set: {data: $data}) {\r\n    returning {\r\n      business_id\r\n      created_at\r\n      data\r\n      id\r\n      isDeleted\r\n      updated_at\r\n    }\r\n  }\r\n}\r\n", "variables": { "business_id": id, "data": data } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getTwilioBotDetails(agent_phone_number: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getTwilioBotData ($phone_number: String!) {\r\n  twilio_by_pk(phone_number: $phone_number) {\r\n    business_id\r\n    created_at\r\n    forward_call_number\r\n    message_service_id\r\n    phone_number\r\n    restaurant_name\r\n    training_data\r\n    updated_at\r\n    voice_lang\r\n    voice_name\r\n    welcome_message\r\n    time_zone\r\n    operating_hours\r\n  }\r\n}\r\n", "variables": { "phone_number": agent_phone_number } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updateTwilioBotDetails(agent_phone_number: any, data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation updateTwilioBotData ($phone_number: String!, $training_data: json) {\r\n  update_twilio_by_pk(pk_columns: {phone_number: $phone_number}, _set: {training_data: $training_data}) {\r\n    welcome_message\r\n    voice_name\r\n    voice_lang\r\n    updated_at\r\n    training_data\r\n    restaurant_name\r\n    phone_number\r\n    message_service_id\r\n    forward_call_number\r\n    created_at\r\n    business_id\r\n  }\r\n}\r\n", "variables": { "phone_number": agent_phone_number, "training_data": data } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  addTwilioBotDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation addTwilioTrainingData ($business_id: uuid, $forward_call_number: String, $phone_number: String, $restaurant_name: String, $voice_lang: String, $voice_name: String, $welcome_message: String, $training_data: json, $message_service_id: String, $time_zone: String, $operating_hours: jsonb) {\r\n  insert_twilio_one(object: {business_id: $business_id, forward_call_number: $forward_call_number, phone_number: $phone_number, restaurant_name: $restaurant_name, voice_lang: $voice_lang, voice_name: $voice_name, welcome_message: $welcome_message, training_data: $training_data, message_service_id: $message_service_id, time_zone: $time_zone, operating_hours: $operating_hours}) {\r\n    business_id\r\n    created_at\r\n    forward_call_number\r\n    message_service_id\r\n    phone_number\r\n    restaurant_name\r\n    training_data\r\n    updated_at\r\n    voice_name\r\n    welcome_message\r\n    voice_lang\r\n    time_zone\r\n    operating_hours\r\n  }\r\n}\r\n", "variables": { "business_id": data.business_id, "forward_call_number": data.forward_call_number, "phone_number": data.agent_phone_number, "restaurant_name": data.business_name, "voice_lang": data.voice_lang, "voice_name": data.voice_name, "welcome_message": data.welcome_message, "training_data": data.training_data, "message_service_id": data.message_service_id, "time_zone": data.time_zone, "operating_hours": data.operating_hours } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getAvailableUSRegions(): Observable<any> {
    return this.http.get(
      `${this.API_URL}/twilio/getAvailableUSRegions`);
  }
  getAvailablePhoneNumbers(code: any): Observable<any> {
    let requestBody = {
      "code": code
    }
    return this.http.post(
      `${this.API_URL}/twilio/getAvailablePhoneNumbers`, requestBody);
  }
  purchasePhoneNumber(data: any): Observable<any> {
    
    // if(businesstypeString != null){
    //   this.businessType = businesstypeString
    // }
    // let webhook;
    //     if (data.language == 'Spanish') {
    //         webhook =  process.env.TWILIO_SPANISH_MESSAGING_SERVICE_WEBHOOK_URL
    //     } else {
           
    //         webhook = process.env.TWILIO_MESSAGING_SERVICE_WEBHOOK_URL
    //     }
    const mainbusinesstypeString = localStorage.getItem("main_business_type_value");
    console.log('mainbusinesstypeString', mainbusinesstypeString)
     
    let requestBody = {
      "phone_number": data.phone_number,
      "language": data.language,
      "service": mainbusinesstypeString == 'Restaurant' || mainbusinesstypeString == 'Restaurante' ? 'restaurant' : 'other' 
    }
    return this.http.post(
      `${this.API_URL}/twilio/purchasePhoneNumber`, requestBody);
  }

  leadCaptureForBotTrainingBody(data: any): Observable<any> {
    let requestBody = {
      "questions": data.questions,
      "trigger": data.trigger,
      "form_name": data.form_name,
      "language": data.language
    }
    return this.http.post(
      `${this.API_URL}/basic/leadCaptureForBotTrainingBody`, requestBody);
  }

  // fixed
  addUserDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation AddUser ($business_id: uuid, $email: String, $language: String) {\r\n  insert_users_one(object: {business_id: $business_id, email: $email, language: $language}) {\r\n    business_id\r\n    city\r\n    country\r\n    created_at\r\n    email\r\n    first_name\r\n    id\r\n    isDeleted\r\n    last_name\r\n    phone\r\n    updated_at\r\n    state\r\n    pincode\r\n    language\r\n  }\r\n}\r\n", "variables": { "email": data.email, "business_id": data.business_id, "language": data.language } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  updateUserDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation UpdateUserDetails($email: String!, $id: uuid!, $first_name: String, $last_name: String, $phone: String, $city: String, $state: String, $pincode: numeric, $country: String) {\r\n  update_users_by_pk(pk_columns: {email: $email, id: $id}, _set: {city: $city, country: $country, first_name: $first_name, last_name: $last_name, phone: $phone, pincode: $pincode, state: $state}) {\r\n    business_id\r\n    city\r\n    country\r\n    created_at\r\n    email\r\n    first_name\r\n    id\r\n    isDeleted\r\n    updated_at\r\n    state\r\n    pincode\r\n    phone\r\n    last_name\r\n  }\r\n}\r\n", "variables": { "id": data.id, "email": data.email, "first_name": data.first_name, "last_name": data.last_name, "phone": data.phone, "city": data.city, "state": data.state, "pincode": data.pincode, "country": data.country } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  addBusinessDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation AddNewBusiness($agent_id: uuid, $business_address: String, $business_name: String, $business_phone: String, $business_type: String,$main_business_type: String, $dummy_voices: json, $poc_contact_number: String, $website_url: String, $business_hours: json, $map_url: String, $poc_number_array: jsonb) {\r\n  insert_businesses_one(object: {agent_id: $agent_id, business_address: $business_address, business_name: $business_name, business_phone: $business_phone, business_type: $business_type, main_business_type: $main_business_type, dummy_voices: $dummy_voices, poc_contact_number: $poc_contact_number, website_url: $website_url, business_hours: $business_hours, map_url: $map_url, poc_number_array: $poc_number_array}) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    business_phone\r\n    business_type\r\n   main_business_type\r\n   created_at\r\n    dummy_voices\r\n    id\r\n    isDeleted\r\n    poc_contact_number\r\n    updated_at\r\n    user_id\r\n    website_url\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "agent_id": data.agent_id, "business_address": data.business_address, "business_name": data.business_name, "business_phone": data.business_phone, "business_type": data.business_type, "main_business_type":data.main_business_type, "dummy_voices": data.dummy_voices, "poc_contact_number": data.poc_contact_number, "website_url": data.website_url, "business_hours": data.business_hours, "map_url": data.map_url, "poc_number_array": data.poc_number_array } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  addUserIdToBusiness(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation AddUserIdToBusiness ($id: uuid!) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {}) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    business_phone\r\n    business_type\r\n    created_at\r\n    dummy_voices\r\n    id\r\n    isDeleted\r\n    poc_contact_number\r\n    updated_at\r\n    user_id\r\n    website_url\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.business_id } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  getAgentDetailsById(agent_id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query GetAgentDetailsById($id: uuid!) {\r\n  agents_by_pk(id: $id) {\r\n    agent_name\r\n    icon\r\n    id\r\n    isDeleted\r\n    voice\r\n    voice_lang\r\n    voice_name\r\n    spanish_voice\r\n  spanish_voice_name\r\n    spanish_voice_lang\r\n  }\r\n}\r\n", "variables": { "id": agent_id } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  disconnectGoogleFromBusiness(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation DisconnectGoogle($id: uuid!, $business_address: String, $business_name: String, $business_phone: String, $business_type: String, $poc_contact_number: String, $business_hours: json, $website_url: String, $map_url: String, $poc_number_array: jsonb) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {business_address: $business_address, business_name: $business_name, business_phone: $business_phone, business_type: $business_type, poc_contact_number: $poc_contact_number, business_hours: $business_hours, website_url: $website_url, map_url: $map_url, poc_number_array: $poc_number_array}) {\r\n    agent_id\r\n    agent_phone_number\r\n    menu\r\n    business_address\r\n    business_name\r\n    website_url\r\n    user_id\r\n    updated_at\r\n    poc_contact_number\r\n    isDeleted\r\n    id\r\n    dummy_voices\r\n    created_at\r\n    business_type\r\n    business_phone\r\n    business_hours\r\n    website_url\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.id, "business_address": data.business_address, "business_name": data.business_name, "business_phone": data.business_phone, "business_type": data.business_type, "poc_contact_number": data.poc_contact_number, "business_hours": data.business_hours, "website_url": data.website_url, "map_url": data.map_url, "poc_number_array": data.poc_number_array } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  disconnectWebsiteFromBusiness(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation DisconnectWebsite($id: uuid!, $website_url: String) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {website_url: $website_url}) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    website_url\r\n    user_id\r\n    updated_at\r\n    poc_contact_number\r\n    isDeleted\r\n    id\r\n    dummy_voices\r\n    created_at\r\n    business_type\r\n    business_phone\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.id, "website_url": data.website_url } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updatePOCinBusinessDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation UpdateAgentandPOCinBusiness($id: uuid!, $agent_id: uuid, $poc_number_array: jsonb) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {agent_id: $agent_id, poc_number_array: $poc_number_array}) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    business_phone\r\n    business_type\r\n    created_at\r\n    dummy_voices\r\n    id\r\n    website_url\r\n    user_id\r\n    updated_at\r\n    poc_contact_number\r\n    isDeleted\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": data.id, "poc_number_array": data.poc_number_array, "agent_id": data.agent_id } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  getUserDetailsByEmail(email: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getUserDetailsByEmail ($email: String) {\r\n  users(where: {email: {_eq: $email}}) {\r\n    business_id\r\n    city\r\n    country\r\n    created_at\r\n    email\r\n    first_name\r\n    id\r\n    isDeleted\r\n    last_name\r\n    phone\r\n    pincode\r\n    state\r\n    updated_at\r\n  }\r\n}\r\n", "variables": { "email": email } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  getBusinessDetailsById(business_id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getBusinessDetailsById($id: uuid!) {\r\n  businesses_by_pk(id: $id) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    business_phone\r\n    business_type\r\n  main_business_type\r\n  created_at\r\n    dummy_voices\r\n    id\r\n    isDeleted\r\n    poc_contact_number\r\n    updated_at\r\n    website_url\r\n    user_id\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n  }\r\n}\r\n", "variables": { "id": business_id } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

   
  updateTwilioDataWithGoogle(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation UpdateTwilioDataWithGoogle($business_id: uuid, $forward_call_number: String, $restaurant_name: String, $welcome_message: String, $training_data: json, $time_zone: String, $operating_hours: jsonb, $map_url: String) {\r\n  update_twilio(where: {business_id: {_eq: $business_id}}, _set: {forward_call_number: $forward_call_number, restaurant_name: $restaurant_name, welcome_message: $welcome_message, training_data: $training_data, time_zone: $time_zone, operating_hours: $operating_hours, map_url: $map_url}) {\r\n    affected_rows\r\n  }\r\n}\r\n", "variables": { "business_id": data.id, "forward_call_number": data.forward_call_number, "restaurant_name": data.restaurant_name, "welcome_message": data.welcome_message, "training_data": data.training_data, "time_zone": data.time_zone, "operating_hours": data.operating_hours, "map_url": data.map_url } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updateChatBotTrainingData(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation updateChatBotTrainingData($business_id: uuid, $data: json) {\r\n  update_chatbot_training_data(where: {business_id: {_eq: $business_id}}, _set: {data: $data}) {\r\n    affected_rows\r\n  }\r\n}\r\n", "variables": { "business_id": data.business_id, "data": data.data } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  getCallRecordingId(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getCallHistory($call_sid: String) {\r\n  twilio_call_history(where: {call_sid: {_eq: $call_sid}}) {\r\n    business_phone_number\r\n    call_sid\r\n    caller_phone_number\r\n    record_sid\r\n   intent_keywords_array\r\n  }\r\n}\r\n", "variables": { "call_sid": data.sid } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  addNewTrainingChatbotrecord(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation addNewTrainingChatbotrecord($business_id: uuid, $data: json) {\r\n  insert_chatbot_training_data_one(object: {business_id: $business_id, data: $data}) {\r\n    business_id\r\n    created_at\r\n    data\r\n    id\r\n    isDeleted\r\n    updated_at\r\n  }\r\n}\r\n", "variables": { "business_id": data.business_id, "data": data.data } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updateAgentDetailsinTwilio(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation updateAgentDetailsinTwilio ($phone_number: String!, $forward_call_number: String, $voice_lang: String, $voice_name: String, $welcome_message: String, $time_zone: String) {\r\n  update_twilio_by_pk(pk_columns: {phone_number: $phone_number}, _set: {forward_call_number: $forward_call_number, voice_lang: $voice_lang, voice_name: $voice_name, welcome_message: $welcome_message, time_zone: $time_zone}) {\r\n    welcome_message\r\n    voice_name\r\n    voice_lang\r\n    updated_at\r\n    training_data\r\n    time_zone\r\n    restaurant_name\r\n    phone_number\r\n    operating_hours\r\n    message_service_id\r\n    forward_call_number\r\n    created_at\r\n    business_id\r\n  }\r\n}\r\n", "variables": { "phone_number": data.agent_phone_number, "forward_call_number": data.poc_contact_number, "voice_lang": data.voice_lang, "voice_name": data.voice_name, "welcome_message": data.welcome_message, "time_zone": data.time_zone } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  updateWelcomeMessage(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation updateWelcomeMessage ($phone_number: String!, $welcome_message: String) {\r\n  update_twilio_by_pk(pk_columns: {phone_number: $phone_number}, _set: {welcome_message: $welcome_message}) {\r\n    business_id\r\n    created_at\r\n    forward_call_number\r\n    map_url\r\n    message_service_id\r\n    operating_hours\r\n    phone_number\r\n    restaurant_name\r\n    time_zone\r\n    training_data\r\n    updated_at\r\n    voice_lang\r\n    voice_name\r\n    welcome_message\r\n  }\r\n}\r\n", "variables": { "phone_number": data.agent_phone_number, "welcome_message": data.welcome_message } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getLinksPath(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getLinks ($phone_number: String) {\r\n  short_io_links(where: {phone_number: {_eq: $phone_number}}) {\r\n    created_at\r\n    domain_id\r\n    id\r\n    link_id\r\n    original_url\r\n    path\r\n    phone_number\r\n    updated_at\r\n  }\r\n}\r\n", "variables": { "phone_number": data.phone_number } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getLinksDetails(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "query getPathDetails ($path: String){\r\n  short_io_links_analytics_aggregate(where: {path: {_eq: $path}}) {\r\n    nodes {\r\n      clicked_count\r\n      end_timestamp\r\n      id\r\n      path\r\n      send_count\r\n      start_timestamp\r\n    }\r\n  }\r\n}\r\n", "variables": { "path": data.path } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  uploadImage(data: any): Observable<any> {
    let formData = new FormData();
    formData.append('image', data);
    return this.http.post(
      `https://appapi.ghc.health/api/progress/upload`,
      formData
    );
  }

  uploadMenu(data: any): Observable<any> {
    let requestBody = {
      menuLink: data
    }

    return this.http.post(
      `${this.API_URL}/excel/toJson`,
      requestBody
    );
  }

  uploadMenuToBusiness(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": "mutation uploadMenuToBusiness ($id: uuid!, $menu: String) {\r\n  update_businesses_by_pk(pk_columns: {id: $id}, _set: {menu: $menu}) {\r\n    agent_id\r\n    agent_phone_number\r\n    business_address\r\n    business_name\r\n    menu\r\n    business_phone\r\n    business_type\r\n    created_at\r\n    dummy_voices\r\n    id\r\n    isDeleted\r\n    poc_contact_number\r\n    updated_at\r\n    website_url\r\n    user_id\r\n    business_hours\r\n    map_url\r\n    poc_number_array\r\n   }\r\n}\r\n", "variables": { "id": data.id, "menu": data.menu } }
    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getKeywords(): Observable<any> {
    const userdetails = localStorage.getItem('userdetails');
    if(!userdetails) {
      return EMPTY;
    }
    const business_id = JSON.parse(userdetails).business_id;

    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": `query MyQuery($business_id: uuid = "") {
                                    phonetics_pronunciation(order_by: {created_at: desc}, where: {business_id: {_eq: $business_id}, is_deleted: {_eq: false}}) {
                                      id
                                      business_id
                                      created_at
                                      updated_at
                                      is_deleted
                                      word
                                      phonetic
                                    }
                                  }`, "variables": { "business_id": business_id } }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  addKeyword(kw: any): Observable<any> {
    const userdetails = localStorage.getItem('userdetails');
    if(!userdetails) {
      return EMPTY;
    }
    const business_id = JSON.parse(userdetails).business_id;

    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": `mutation x($business_id: uuid = "", $word: String = "", $phonetic: String = "") {
                                    insert_phonetics_pronunciation(objects: {business_id: $business_id, word: $word, phonetic: $phonetic}) {
                                      returning {
                                        id
                                        business_id
                                        created_at
                                        updated_at
                                        is_deleted
                                        word
                                        phonetic
                                      }
                                    }
                                  }`, "variables": { "business_id": business_id, "word": kw.word, "phonetic": kw.phonetic } }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  updateKeyword(kw: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": `mutation updateKeyword($id: uuid = "", $word: String = "", $phonetic: String = "") {
                                  update_phonetics_pronunciation(where: {id: {_eq: $id}}, _set: {word: $word, phonetic: $phonetic}) {
                                    returning {
                                      id
                                      business_id
                                      created_at
                                      updated_at
                                      is_deleted
                                      word
                                      phonetic
                                    }
                                  }
                                }`, "variables": { "id": kw.id, "word": kw.word, "phonetic": kw.phonetic } }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }
  
  // soft delete
  deleteKeyword(kw: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { "query": `mutation softDeleteKeyword($id: uuid = "") {
                          update_phonetics_pronunciation(_set: {is_deleted: true}, where: {id: {_eq: $id}}) {
                            returning {
                              id
                              business_id
                              is_deleted
                              created_at
                              phonetic
                              updated_at
                              word
                            }
                          }
                       }`, "variables": { "id": kw.id } }

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    )
  }

  getBlogs(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('x-hasura-admin-secret', this.HASURA_SECRET_KEY);
    headers = headers.append('content-type', 'application/json');

    let requestBody = { 
      "query": `query getBlogs {
        blogs (where: {is_deleted: {_eq: false}}){
          Description
          id
          image_name
          title
          link
          country
          is_deleted
        }
      }`
    };

    return this.http.post(
      `${this.HASURA_API_URL}`, requestBody, { headers: headers }
    );
}
}