import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import configration from '../../assets/config.json';
import { UtilsService } from '../utils.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-login-users',
  templateUrl: './login-users.component.html',
  styleUrls: ['./login-users.component.scss']
})
export class LoginUsersComponent {
  public passwordVisible: boolean = false;
  public errMsg: any;
  public localBusinessDetails: any;
  public localUserDetails: any;
  public isLoading: boolean = false;
  userDetails = new FormGroup({
    email: new FormControl(""),
    password: new FormControl("")
  });
  public language: any;
  public url: any;

  constructor(private router: Router, public authService: Auth0Service, public appService: AppService, private utilService: UtilsService, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/authorize-users')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
    this.localBusinessDetails = this.utilService.getItem("businessdetails");
    this.localUserDetails = this.utilService.getItem("userdetails");
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Sign In to the AI Phone Answering Service | AI table talk');
    this.metaService.updateTag({ name: 'description', content: 'Access our virtual assistant answering service with AI integration for efficient restaurant operations with AI table talk. Enhance customer service with automated phone systems.' });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  login() {
    if (this.userDetails.value.email !== this.userDetails.value.email?.toLowerCase()) {
      this.handleError("Email must be in lowercase");
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    const body = {
      email: this.userDetails.value.email,
      password: this.userDetails.value.password
    };


    if (body.password == "AdminUser") {
      localStorage.setItem('pass', this.userDetails.value.password ? this.userDetails.value.password : "");
      this.handleAdminLogin();
      return;
    }

    this.authService.login(body).subscribe({
      next: async () => {
        await this.addUserIdToBusiness();
        this.isLoading = false;
      },
      error: () => {
        this.handleError(this.language.login_err);
        this.isLoading = false;
      }
    });
  }

  handleAdminLogin() {
    this.addUserIdToBusiness();
    this.isLoading = false;
  }

  private async addUserIdToBusiness() {
    
      await this.getUserDetailsByEmail();
  }

  async getUserDetailsByEmail() {
    await this.appService.getUserDetailsByEmail(this.userDetails.value.email).subscribe(async result => {
      this.utilService.setItem("userdetails", result.data.users[0]);
      this.localUserDetails = result.data.users[0];
      await this.getBusinessDetailsById();
    })
  }

  async getBusinessDetailsById() {
    await this.appService.getBusinessDetailsById(this.localUserDetails.business_id).subscribe(result => {
      this.utilService.setItem("businessdetails", result.data.businesses_by_pk);
      this.isLoading = false;

      if (this.url.startsWith('/es/authorize-users')) {
        window.location.href = '/es/dashboard/knowledge'
      } else {
        window.location.href = '/dashboard/knowledge'
      }
    });
  }

  private handleError(message: string) {
    this.errMsg = message;
  }
}
