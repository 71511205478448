import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-signup-users',
  templateUrl: './signup-users.component.html',
  styleUrls: ['./signup-users.component.scss']
})
export class SignupUsersComponent {
  public errMsg: any;
  public businessDetails: any;
  public passwordVisible: boolean = false;
  public passwordVisible2: boolean = false;
  public isLoading: boolean = false;
  userDetails = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    businessEmail: new FormControl("", [Validators.required, Validators.email]),
    businessId: new FormControl(""),
    password: new FormControl(""),
    repassword: new FormControl("")
  });
  public language: any;
  public url: any;
  public localUserDetailstogetBusinessId: any;

  constructor(private router: Router, public authService: Auth0Service, public appService: AppService, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/signup-users')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
    this.businessDetails = this.utilsService.getItem('businessdetails');
    // if (!this.businessDetails) {
    //   if (this.url.startsWith('/es/signup')) {
    //     this.router.navigate(["/es/get-started/all"]);
    //   } else {
    //     this.router.navigate(["/get-started/all"]);
    //   }
    // }
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordVisibility2() {
    this.passwordVisible2 = !this.passwordVisible2;
  }

  signUp() {
    this.isLoading = true;
    this.errMsg = "";

    if (this.userDetails.value.password !== this.userDetails.value.repassword) {
      this.errMsg = "Passwords don't match!";
      this.isLoading = false;
      return;
    }
    if (this.userDetails.value.email !== this.userDetails.value.email?.toLowerCase()) {
      this.errMsg = "Email must be in lowercase.";
      this.isLoading = false;
      return;
    }

    const body = {
      email: this.userDetails.value.email,
      password: this.userDetails.value.password
    };


    

    this.authService.signUp(body).subscribe((res) => {
      try {
        let businessId: string | null = null;
    
        this.appService.getUserDetailsByEmail(this.userDetails.value.businessEmail).subscribe((result) => {
          if (result?.data?.users?.length) {
            businessId = result.data.users[0].business_id;
            console.log('Fetched business_id:', businessId);
          }
    
          res.business_id = businessId;
          res.language = this.language.language;
    
          console.log('Final res.business_id:', res); 
          
          this.appService.addUserDetails(res).subscribe((result) => {
            if (!result.errors) {
              this.utilsService.setItem('userdetails', result.data.insert_users_one);
              if (this.url.startsWith('/es/signup-users')) {
                window.location.href = '/es/authorize-users';
              } else {
                window.location.href = '/authorize-users';
              }
            }
          }, (error) => {
            this.errMsg = "Something went wrong while adding user details.";
          });
        }, (error) => {
          this.errMsg = "Something went wrong while fetching user details.";
        });
    
      } catch (error) {
        this.errMsg = "Something went wrong.";
      } finally {
        this.isLoading = false;
      }
    }, (error) => {
      this.errMsg = "Invalid email or password.";
      this.isLoading = false;
    });
    
  }
}
