import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';

interface Blog {
  Description: string;
  country: string;
  id: string;
  image_name: string;
  link: string;
  title: string;
  is_deleted: string;
}

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public host: string = '';
  public lang: string = '';
  public blogsList: Blog[] = [];
  public relatedBlogs: Blog[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.initializeComponent();
    });
  }

  private initializeComponent(): void {
    this.host = window.location.href.split('/').pop() || '';

    const urlSegments = window.location.href.split('/');
    this.lang = urlSegments[urlSegments.length - 3];

    this.getBlogDetails();
  }

  private getBlogDetails(): void {
    this.appService.getBlogs().subscribe(
      (res) => {
        if (res?.data?.blogs) {
          this.blogsList = res.data.blogs.filter((blog: Blog) => 
            blog.country === (this.lang === 'es' ? 'es' : 'en')
          );

          const filteredBlogs = this.blogsList.filter((blog: Blog) => blog.link.split('/').pop() !== this.host);
          this.relatedBlogs = this.getRandomBlogs(filteredBlogs, 7);

          console.log('Filtered Blogs:', this.blogsList);
          console.log('Related Blogs:', this.relatedBlogs);
        }
      },
      (error) => {
        console.error('Error fetching blogs:', error);
      }
    );
  }

  private getRandomBlogs(blogs: Blog[], count: number): Blog[] {
    if (blogs.length <= count) {
      return blogs; 
    }
    return blogs.sort(() => Math.random() - 0.5).slice(0, count); 
  }

  public navigateToBlog(blogLink: string): void {
    this.router.navigateByUrl(blogLink).then(() => {
      window.location.reload(); 
    });
  }
}
