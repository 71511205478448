<main *ngIf="!editContent">
    <h1>Path Ways</h1>

    <app-socials></app-socials>

    <section class="preview" *ngIf="twilioBotDetails && businessDetails.agent_phone_number">

        <div class="title">
            <h3>{{language.welcome_message_heading}}</h3>
        </div>

        <div class="message-box">
            <p>
                {{twilioBotDetails.welcome_message}}
            </p>

            <button (click)="editMessagePopup()">
                <span class="material-symbols-outlined">
                    edit
                </span>
                {{language.edit}}
            </button>
        </div>
    </section>

    <section class="preview" *ngIf="businessDetails.agent_phone_number">

        <div class="title">
            <h3>{{language.add_conversational_flow_heading}}</h3>
            <button routerLink="{{language.dashboard_new_pathway}}"><span class="material-symbols-outlined">
                    add
                </span>
                {{language.add_pathways}}</button>
        </div>

        <div class="audios-collection" *ngIf="audios">
            <div class="audio" *ngFor="let audio of audios; let i = index">
                <div class="heading">
                    <span class="material-symbols-outlined" (click)="openDropdown(i)">
                        more_vert
                    </span>
                    <div class="dropdown" *ngIf="openDropdownIndex === i" [ngClass]="{ 'display': openedDropdown }">
                        <p (click)="editTrainedFlow(audio)">
                            <span class="material-symbols-outlined">
                                edit
                            </span>
                            {{language.edit}}
                        </p>
                        <p (click)="deleteTrainedData(i, audio)">
                            <span class="material-symbols-outlined">
                                delete
                            </span>
                            {{language.delete}}
                        </p>
                    </div>
                </div>
                <div class="content">
                    <p>
                        {{audio.task_name}}
                    </p>
                </div>
            </div>
        </div>
    </section>
</main>

<main *ngIf="editContent">
    <a class="back" (click)="goback()">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <section class="csv">
        <h1>{{editContent.task_name}}</h1>

        <div class="csvform">
            <form [formGroup]="trainAgent" class="addon-container">
                <div>
                    <label>Action</label>
                    <select formControlName="action">
                        <option [value]="option.value" selected="editContent.action"
                            *ngFor="let option of language.agentDropdownOptions">
                            {{option.title}}
                        </option>
                    </select>
                </div>
                <div>
                    <label>{{language.task_name}}<span>*</span></label>
                    <input type="text" placeholder="Task Name" formControlName="task_name" required />
                </div>
                <div>
                    <label>{{language.ques_for_agent}}</label>
                    <textarea col="10" row="10" type="text" formControlName="question"></textarea>
                </div>

                <div *ngIf="editContent.action == 'Give Quick Answer'">
                    <label>{{language.then_agent_will_say}}</label>
                    <textarea col="10" row="10" type="text" formControlName="answer"></textarea>
                </div>
                <div *ngIf="editContent.action == 'Direct Caller to Website'">
                    <label>{{language.your_website_link}}</label>
                    <input type="text" formControlName="website_url">
                </div>
                <div *ngIf="editContent.action == 'Smart Call Routing'">
                    <label>{{language.transfer_phone_number}}</label>
                    <input type="text" formControlName="phone_number">
                </div>
                <!-- <div *ngIf="editContent.action == 'Take Message' || editContent.action == 'Transfer Call'">
                    <label>{{language.poc_number}}</label>
                    <input type="text" formControlName="phone_number">
                </div> -->

                <!-- <div *ngIf=" editContent.action=='Take Message' || editContent.action=='Transfer Call'">
                    <label>{{language.poc_number}}</label>
                    <select formControlName=" phone_number">
                    <option [value]="option" selected="editContent.phone_number"
                        *ngFor="let option of businessDetails.poc_number_array">
                        {{option}}
                    </option>
                    </select>
                </div> -->

                <button *ngIf="editContent.action !== 'Lead Capture'" [disabled]="!trainAgent.valid"
                    (click)="finalSaveAddMore()">{{language.save}}</button>
            </form>
        </div>

        <div class="questions" *ngIf="editContent.action == 'Lead Capture'">
            <h3>{{language.custom_ques}}</h3>
            <form [formGroup]="questionForm">
                <div formArrayName="questions">
                    <div class="question-item" *ngFor="let question of questions.controls; let i=index"
                        [formGroupName]="i">
                        <input formControlName="question" placeholder="{{language.enter_question_placeholder}}">
                        <select formControlName="type">
                            <option value="" disabled selected>{{language.ques_type}}</option>
                            <option *ngFor="let type of language.flow_options" value="{{type}}">{{type}}</option>
                        </select>
                        <span class="material-symbols-outlined delete-icon" (click)="removeQuestion(i)">delete</span>
                    </div>
                </div>
            </form>
        </div>

        <div class="buttons" *ngIf="editContent.action == 'Lead Capture'">
            <button type="submit" (click)="addQuestion()">
                {{language.add_questions}}
            </button>
            <button [disabled]="!questions.controls.length" (click)="finalSaveAddMore()">{{language.save}}</button>
        </div>
    </section>
</main>

<div class="popup-overlay" *ngIf="isEditMessagePopupOpen">
    <div class="popup-content">
        <div class="title">
            <h2>{{language.edit_welcome_message}}</h2>
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content">
            <form>
                <h4>{{language.message}}</h4>
                <div>
                    <textarea [(ngModel)]="welcomeMessage" [ngModelOptions]="{standalone: true}"
                        placeholder="Welcome Message" col="5" rows="5" type="text" required></textarea>
                </div>
                <button type="submit" [disabled]="!welcomeMessage" (click)="updateWelcomeMessage()">
                    {{language.save}}
                </button>
            </form>
        </div>
    </div>
</div>