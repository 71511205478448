import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { PricingComponent } from './pricing/pricing.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { RequestComponent } from './request/request.component';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetstartedComponent } from './getstarted/getstarted.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { KnowledgeComponent } from './knowledge/knowledge.component';
import { FlowComponent } from './flow/flow.component';
import { DirectoryComponent } from './directory/directory.component';
import { PerformanceComponent } from './performance/performance.component';
import { SettingsComponent } from './settings/settings.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CallLogsComponent } from './call-logs/call-logs.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { SignupComponent } from './signup/signup.component';
import { TrainAgentComponent } from './train-agent/train-agent.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { NewPathwayComponent } from './new-pathway/new-pathway.component';
import { AuthGuard } from './auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './spinner/spinner.component';
import { SocialsComponent } from './socials/socials.component';
import { GetnumberComponent } from './getnumber/getnumber.component';
import { IntegrationComponent } from './integration/integration.component';
import { BlogsComponent } from './blogs/blogs.component';
import { PostComponent } from './post/post.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { NumberPopupComponent } from './number-popup/number-popup.component';
import { SignupUsersComponent } from './signup-users/signup-users.component';
import { LoginUsersComponent } from './login-users/login-users.component'; // Import BrowserAnimationsModule

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ProductComponent,
    PricingComponent,
    NewsletterComponent,
    RequestComponent,
    GetstartedComponent,
    LoginComponent,
    DashboardComponent,
    KnowledgeComponent,
    FlowComponent,
    DirectoryComponent,
    PerformanceComponent,
    SettingsComponent,
    SidebarComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    CallLogsComponent,
    SignupComponent,
    TrainAgentComponent,
    AccountSettingsComponent,
    NewPathwayComponent,
    SpinnerComponent,
    SocialsComponent,
    GetnumberComponent,
    IntegrationComponent,
    BlogsComponent,
    PostComponent,
    PrivacyPolicyComponent,
    LicenseAgreementComponent,
    NumberPopupComponent,
    SignupUsersComponent,
    LoginUsersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [AppService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
