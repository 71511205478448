<main>
    <section class="csv">
        <h1>Train AI Model</h1>

        <div class="csvform">
            <form [formGroup]="trainAgent" class="addon-container">
                <div>
                    <label>Action<span>*</span></label>
                    <select formControlName="action" (change)="onOptionChange($event)" required>
                        <option value="{{option.value}}" *ngFor="let option of language.agentDropdownOptions">
                            {{option.title}}
                        </option>
                    </select>
                </div>
                <div>
                    <label>{{language.task_name}}<span>*</span></label>
                    <input type="text" placeholder="{{language.task_name}}" formControlName="task_name" required />
                </div>
                <div>
                    <label>{{language.ques_for_agent}}</label>
                    <textarea col="10" row="10" type="text" formControlName="question"></textarea>
                </div>

                <div *ngIf="trainAgent.value.action == 'Give Quick Answer'">
                    <label>{{language.then_agent_will_say}}</label>
                    <textarea col="10" row="10" type="text" formControlName="answer"></textarea>
                </div>

                <div *ngIf="trainAgent.value.action == 'Direct Caller to Website'">
                    <label>{{language.your_website_link}}</label>
                    <input type="text" formControlName="website_url">
                </div>
                <div *ngIf="trainAgent.value.action == 'Smart Call Routing'">
                    <label>{{language.transfer_phone_number}}</label>
                    <input type="text" formControlName="phone_number">
                </div>

                <!-- <div *ngIf="trainAgent.value.action == 'Take Message' || trainAgent.value.action == 'Transfer Call'">
                    <label>{{language.poc_number}}</label>
                    <input type="text" formControlName="phone_number"> -->
                <!-- <select formControlName="phone_number" required>
                        <option value="{{option}}" *ngFor="let option of businessDetail.poc_contact_number">
                            {{option}}
                        </option>
                    </select> -->
                <!-- </div> -->

                <button *ngIf="trainAgent.value.action !== 'Lead Capture'" [disabled]="!trainAgent.valid"
                    (click)="finalSaveAddMore()">{{language.save}}</button>
            </form>
        </div>

        <div class="questions" *ngIf="trainAgent.value.action == 'Lead Capture'">
            <h3>{{language.custom_ques}}</h3>
            <form [formGroup]="questionForm">
                <div formArrayName="questions">
                    <div class="question-item" *ngFor="let question of questions.controls; let i=index"
                        [formGroupName]="i">
                        <input formControlName="question" placeholder="{{language.enter_question_placeholder}}">
                        <select formControlName="type">
                            <option value="" disabled selected>{{language.ques_type}}</option>
                            <option *ngFor="let name of language.flow_options" value="{{name}}">{{name}}</option>
                        </select>
                        <span class="material-symbols-outlined delete-icon" (click)="removeQuestion(i)">delete</span>
                    </div>
                </div>
            </form>
        </div>

        <div class="buttons" *ngIf="trainAgent.value.action == 'Lead Capture'">
            <button type="submit" (click)="addQuestion()">
                {{language.add_questions}}
            </button>
            <button [disabled]="!questions.controls.length" (click)="finalSaveAddMore()">{{language.save}}</button>
        </div>

    </section>
</main>